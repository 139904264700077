import * as React from 'react'
import Link from '../components/Link'
import not_found from '../images/uploads/404.svg'
const NotFoundPage = () => {
  return (
    <main>
      <div className="mx-auto max-w-5xl flex flex-col items-center justify-center p-4 md:px-8">
        <img src={not_found} alt="404" className="w-3/4" />
        <p className="font-display md:text-2xl mt-4">
          The page you looking for does not exist!
        </p>
        <Link to="/" variant="primary" size="extra">
          Go back Home
        </Link>
      </div>
    </main>
  )
}

export default NotFoundPage
